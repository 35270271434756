import stepMixin from '../../../mixins/stepMixin'
import customerMixin from '../../../mixins/customerMixin'
import checkTaskMixin from '../../../mixins/checkTaskMixin'
import Camera from '../../camera/Camera.vue'
import TextList from '../../textList/TextList.vue'
import FormCheck from '../../form/formCheck/FormCheck.vue'
import FormInput from '../../form/formInput/FormInput.vue'
import { mapActions } from 'vuex'

export default {
    name: 'DrivingLicense',

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        }
    },

    components: {
        Camera,
        TextList,
        FormCheck,
        FormInput
    },

    mixins: [stepMixin, checkTaskMixin, customerMixin],

    data() {
        return {
            dataMatch: null,
            deniesPhoto: null
        }
    },

    computed: {
        dataMatchLabel() {
            if (this.isDrivingServiceTask) {
                return 'Daten von Führerschein und Fahrer/in stimmen überein'
            } else if (this.hasAuthorizedRepresentative && !this.contractPartnerOnSite) {
                return 'Daten von Führerschein und Bevollmächtigte/r stimmen überein'
            } else {
                return 'Daten von Führerschein und Primäre/r Fahrer/in stimmen überein'
            }
        },

        customerUseEasycheck() {
            return this.form.vehicle.customer?.useEasycheck
        }
    },

    methods: {
        ...mapActions({
            setForm: 'form/setForm'
        }),

        validate() {
            let isStepValid = this.isDataMatchValid() && this.isEasycheckNumberValid()
            this.$emit('update:stepIsValid', isStepValid)
        },

        isDataMatchValid() {
            return !!this.field?.dataMatch;
        },

        isEasycheckNumberValid() {
            if (this.customerUseEasycheck) {
                if (!this.field?.easycheckNumber) {
                    return false
                }

                return /^[a-zA-Z0-9]+$/.test(this.field.easycheckNumber)
            }

            return true
        }
    },
}
